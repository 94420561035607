const linkCode = `
(function handleHTMLLinkClick(event) {
  event.preventDefault();
  console.log('a link was clicked!', event)
  try {
    const { href } = event.target;
    
    let newValue = href;
    if(!newValue){
      console.log('Target: ', event.target)
      throw(Error('Link is empty'))
      return
    }
    if(newValue.startsWith('http://' + window.location.host)) {
      newValue = newValue.replace('http://' + window.location.host, '');
    }
    if(newValue.startsWith('https://' + window.location.host)) {
      newValue = newValue.replace('https://' + window.location.host, '');
    }
    
    
    document.getElementById('hidden-link-handler-input').value = newValue;
    document.getElementById('hidden-link-handler-submit').click();
  } catch(e) {
    console.log('RedCloud: Failed to handle link click logic.', e)
  }
})(event)
`;
function addTargetBlank(htmlString) {
  const regex = /<a\s+(.*?)>/gi;
  const replacement = `<a $1 target="_blank" onclick="${linkCode}">`;
  const editedHtml = htmlString.replace(regex, replacement);
  return editedHtml;
}

export const parseInjectHTMLBeforeRender = (htmlString) => {
  if (!htmlString) return "";
  let newHTML = htmlString;
  // Check anchor tag links
  newHTML = addTargetBlank(newHTML);

  return newHTML;
};

export function decodeHTMLEntities(input) {
  const entityMap = {
    "&lt;": "<",
    "&gt;": ">",
    "&amp;": "&",
    "&quot;": '"',
    "&#39;": "'",
    "&#x2F;": "/",
    "&#x60;": "`",
    "&#x3D;": "=",
    "&#x20;": " ",
    // Add more entity mappings as needed
  };

  let htmlString = input.replace(
    /&(lt|gt|amp|quot|#39|#x2F|#x60|#x3D|#x20);/g,
    (match) => entityMap[match]
  );

  return htmlString;
}
